import { SyncOutlined } from "@ant-design/icons";
import { Button, Progress, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import PermissionRequired from "../common/PermissionRequired";
import Toolbar from "../common/Toolbar";
import { withTooltip } from "../util";
import EditActivity from "./EditActivity";
import UpdateDoneQuantity from "./UpdateDoneQuantity";

const getConstraint = (record) => {
    if (record.authorizationState === "WAITING")
        return <Tag color={"red"}>Attesa autorizzazione</Tag>;

    if (record.municipalityOrdinanceState === "WAITING")
        return <Tag color={"red"}>Attesa ordinanza</Tag>;

    if (record.municipalityOrdinanceState === "OK")
        return (
            <Tag color={"volcano"}>
                Ordinanza dal {dayjs(record.municipalityOrdinanceStartDate).format("DD/MM")} al{" "}
                {dayjs(record.municipalityOrdinanceEndDate).format("DD/MM")}
            </Tag>
        );

    return "";
};

export default function ActivitiesTable({ addAction, data, hiddenColumns }) {
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [activityToUpdateQty, setActivityToUpdateQty] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const user = useContext(UserContext);

    hiddenColumns = hiddenColumns ?? [];

    const columns = [
        {
            key: "id",
            dataIndex: "id",
            width: 120,
            title: "Codice attività",
        },
        {
            key: "activityType",
            dataIndex: "type",
            width: 200,
            title: "Categoria",
            render: (type) => <Tag color={type.color}>{type.name}</Tag>,
        },
        {
            key: "order",
            dataIndex: "order",
            title: "Commessa",
            hidden: hiddenColumns.includes("order"),
            width: 200,
            render: (order) => <Link to={`/orders/${order.id}`}>{order.title}</Link>,
        },
        {
            key: "customer",
            dataIndex: "order",
            width: 130,
            hidden: hiddenColumns.includes("customer"),
            title: "Cliente",
            render: (order) => order.customer,
        },
        {
            key: "address",
            dataIndex: "order",
            width: 200,
            hidden: hiddenColumns.includes("address"),
            title: "Indirizzo",
            ellipsis: true,
            render: (order) => withTooltip(order.fullAddress),
        },
        {
            key: "constraints",
            title: "Vincoli",
            width: 200,
            render: getConstraint,
        },
        {
            key: "notes",
            title: "Note",
            dataIndex: "notes",
            width: 150,
            ellipsis: true,
            render: x => withTooltip(x),
        },
        {
            key: "toDo",
            title: "Realizzato",
            width: 150,
            ellipsis: true,
            render: x => `${x.doneQuantity} / ${x.toDoQuantity} ${x.type.measureUnit}`
        },
        {
            key: "doneWork",
            title: "Avanzamento",
            width: 100,
            render: (activity) => (
                <>
                    <Progress
                        percent={Math.floor((activity.doneQuantity * 100) / activity.toDoQuantity)}
                    />
                </>
            ),
        },
    ];

    useEffect(() => {
        setSelectedKeys([]);
        setDataSource(data);
    }, [data]);

    return (
        <div>
            <EditActivity
                activityId={selectedActivity}
                onCancel={() => setSelectedActivity(null)}
                onFinish={(newData) => {
                    console.log(dataSource.some((x) => x.id === selectedActivity));
                    setDataSource(
                        dataSource.map((x) =>
                            x.id === selectedActivity ? { ...x, ...newData } : x,
                        ),
                    );
                    setSelectedActivity(null);
                }}
            />
            <UpdateDoneQuantity
                activityId={activityToUpdateQty}
                onUpdateFinished={(newData) => {
                    setDataSource(
                        dataSource.map((x) =>
                            x.id === activityToUpdateQty
                                ? {
                                      ...x,
                                      doneQuantity: newData.doneQuantity,
                                      toDoQuantity: newData.toDoQuantity,
                                  }
                                : x,
                        ),
                    );
                    setActivityToUpdateQty(null);
                }}
                onCancel={() => setActivityToUpdateQty(null)}
            />
            <Toolbar
                addAction={user.permissions?.includes("activity.create") ? addAction : null}
                selectedKeys={selectedKeys}
                editAction={
                    user.permissions?.includes("activity.update") ? setSelectedActivity : null
                }
                extra={(keys) => (
                    <PermissionRequired name={"activity.update"}>
                        <Button
                            disabled={keys.length === 0}
                            onClick={() => setActivityToUpdateQty(keys[0])}
                            type="text"
                            icon={<SyncOutlined />}
                        >
                            Aggiorna avanzamento
                        </Button>
                    </PermissionRequired>
                )}
            />
            <Table
                size="small"
                tableLayout="fixed"
                columns={columns}
                onRow={(record, _) => {
                    return {
                        onClick: () => {
                            setSelectedKeys([record.key]);
                        },
                    };
                }}
                rowSelection={{
                    selectedRowKeys: selectedKeys,
                    onChange: setSelectedKeys,
                    type: "radio",
                }}
                scroll={{ x: columns.reduce((prev, curr) => prev + curr.width, 0) }}
                dataSource={dataSource.map((x) => ({ ...x, key: x.id }))}
            />
        </div>
    );
}
