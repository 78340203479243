import axios from "axios";
import { message } from "antd";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const mapError = (data) =>
    ({
        "migration.contract_listing_element_not_found": (content) =>
            `Voce ${content.listingElement.refId} non presente nel contratto ${content.destContract.name}`,

        "migration.migration_not_found": (content) =>
            `Nessuna associazione trovata per la voce ${content.listingElement.refId} su contratto ${content.destContract.name}`,
    })[data.type]?.(data.content);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (error.response.status === 403) {
            message.error("Permessi insufficienti", 5);
        } else {
            const errorMsg = mapError(error.response.data);
            message.error("Errore: " + (errorMsg ?? "axios.server.error"), 8);
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
