import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { Avatar, Button, Col, message, Row, Space, Table, Tooltip } from "antd";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { PlusOutlined, SearchOutlined, SmallDashOutlined, UserOutlined } from "@ant-design/icons";
import { filterOption, formatDateTime, formatToLocalDateTime, withTooltip } from "../util";
import OrderSearchFilters from "./OrderSearchFilters";
import PermissionRequired from "../common/PermissionRequired";
import dayjs from "dayjs";

const columns = [
    {
        key: "title",
        dataIndex: "title",
        width: 250,
        ellipsis: true,
        title: "Nome",
        render: (title, record) => <Link to={"" + record.id}>{withTooltip(title)}</Link>,
    },
    {
        key: "customer",
        dataIndex: "customer",
        width: 100,
        ellipsis: true,
        title: "Cliente",
        render: (customer) => customer.name,
    },
    {
        key: "sentAccountings",
        dataIndex: "sentOrderAccounting",
        title: "Contabilità inviate",
        width: 100,
        ellipsis: true,
        render: (_, record) => `${record.sentAccountingCount}/${record.totalAccountingCount}`,
    },
    {
        key: "address",
        dataIndex: "fullAddress",
        width: 250,
        ellipsis: true,
        title: "Indirizzo",
        render: (x) => withTooltip(x),
    },
    {
        key: "lastOpTs",
        title: "Ultima operazione",
        width: 120,
        ellipsis: true,
        dataIndex: "lastUpdateTs",
        render: (x) => withTooltip(formatDateTime(x)),
    },
];

export default function Orders() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ?? 0;
    const pageSize = searchParams.get("pageSize") ?? 10;

    useEffect(() => {
        setLoading(true);
        const params = [...searchParams.entries()].reduce(
            (acc, [k, v]) => ({ ...acc, [k]: v }),
            {},
        );
        axiosInstance
            .get("order", { params })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    }, [searchParams]);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Row>
                <Col span={12}>
                    <BackTitle backEnabled={false} title={"Commesse"} padding={0} />
                </Col>
                <Col span={12}>
                    <Space style={{ height: "100%", width: "100%", justifyContent: "end" }}>
                        <PermissionRequired name={"order.create"}>
                            <Button
                                type={"primary"}
                                icon={<PlusOutlined />}
                                onClick={() => navigate("new")}
                                style={{ width: 150 }}
                            >
                                Nuova
                            </Button>
                        </PermissionRequired>
                    </Space>
                </Col>
            </Row>
            <OrderSearchFilters style={{ marginTop: 16 }} />
            <Table
                size="small"
                loading={loading}
                columns={columns}
                onChange={(pagination) => {
                    searchParams.set("page", "" + (pagination.current - 1));
                    searchParams.set("pageSize", "" + pagination.pageSize);
                    setSearchParams(searchParams, { replace: true });
                }}
                tableLayout={"fixed"}
                scroll={{ x: columns.reduce((acc, x) => acc + x.width, 0) }}
                pagination={{
                    current: Number(page) + 1,
                    showTotal: (total) => "Totale: " + total,
                    pageSize: pageSize,
                    total: data.count ?? 0,
                }}
                dataSource={data.data?.map((x) => ({ ...x, key: x.id })) ?? []}
            />
        </div>
    );
}
