import { Form, Input, Table, Tooltip, Typography } from "antd";
import axiosInstance from "../api";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Search from "antd/lib/input/Search";
import Highlighter from "react-highlight-words";
import { withTooltip } from "../util";

const { Text } = Typography;

const EditableContext = createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        /*form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });*/
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            form.resetFields();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
            >
                <Input
                    style={{ width: "100%", textAlign: "right" }}
                    ref={inputRef}
                    onPressEnter={save}
                    onBlur={save}
                />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

export default function OrderAccountingReportTable({ report, refresh }) {
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            key: "accounting",
            title: "Foglio",
            sorter: (a, b) => a.accountingId - b.accountingId,
            width: 80,
            ellipsis: true,
            dataIndex: "accounting",
            render: x=> x.name
        },
        {
            key: "section",
            title: "Tratta",
            width: 80,
            ellipsis: true,
            render: x => withTooltip(x),
            sorter: (a, b) => a.sectionId - b.sectionId,
            dataIndex: "section",
        },
        {
            key: "refId",
            title: "Voce",
            width: 120,
            ellipsis: true,
            render: (_,record) =>
                withTooltip(
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: "#ffc069",
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={(record.listingElement?.refId ?? "EXTRA").toString()}
                    />,
                ),
        },
        {
            key: "description",
            title: "Descrizione",
            width: 220,
            ellipsis: true,
            render: (_,record) =>
                withTooltip(
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: "#ffc069",
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={(record.listingElement?.description ?? record.description).toString()}
                    />,
                ),
        },
        {
            key: "quantity",
            title: "Q.ta",
            width: 70,
            ellipsis: true,
            align: "end",
            sorter: (a, b) => a.quantity - b.quantity,
            render: (x) => x.toLocaleString("it-IT"),
            dataIndex: "quantity",
        },
    ];

    const priceColumns = [
        {
            key: "paidQuantity",
            title: "Q.ta saldata",
            width: 70,
            ellipsis: true,
            align: "end",
            sorter: (a, b) => a.paidQuantity - b.paidQuantity,
            render: (x) => x.toLocaleString("it-IT"),
            editable: true,
            dataIndex: "paidQuantity",
        },
        {
            key: "toPayQuantity",
            title: "Q.ta da saldare",
            width: 70,
            ellipsis: true,
            align: "end",
            sorter: (a, b) => a.quantity - a.paidQuantity - (b.quantity - b.paidQuantity),
            render: (record) => (record.quantity - record.paidQuantity).toLocaleString("it-IT"),
        },
        {
            key: "price",
            title: "Importo",
            align: "end",
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.price - b.price,
            dataIndex: "price",
            render: (x) => x.toLocaleString("it-IT", { minimumFractionDigits: 2 }),
        },
        {
            key: "paidPrice",
            title: "Importo saldato",
            align: "end",
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.paidPrice - b.paidPrice,
            dataIndex: "paidPrice",
            render: (x) => x.toLocaleString("it-IT", { minimumFractionDigits: 2 }),
        },
        {
            key: "toPay",
            title: "Importo da saldare",
            align: "end",
            width: 100,
            ellipsis: true,
            sorter: (a, b) => a.toPay - b.toPay,
            dataIndex: "toPay",
            render: (x) => x.toLocaleString("it-IT", { minimumFractionDigits: 2 }),
        },
    ];

    const handleSave = (record) => {
        if (record.paidQuantity) {
            setLoading(true);
            axiosInstance
                .patch(`/accountingItem/${record.id}`, {
                    paidQuantity: Number(record.paidQuantity),
                })
                .then(() => refresh())
                .finally(() => setLoading(false));
        }
    };

    const finalColumns = [...columns, ...(report.hasPrices ? priceColumns : [])].map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState();

    useEffect(() => {
        const data = (report.lines?.sort((a, b) => a.id - b.id) ?? []).map((x) => ({
            ...x,
            key: x.id,
        }));

        if (searchText) {
            setTableData(
                data.filter(
                    (record) =>
                        (record.listingElement?.refId ?? "EXTRA").toString().toLowerCase().includes(searchText.toLowerCase()) ||
                        (record.listingElement?.description ?? record.description)
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toLowerCase()),
                ),
            );
        } else {
            setTableData(data);
        }
    }, [report, searchText]);

    const totalWidth = finalColumns.reduce((acc, x) => (x.width ?? 0) + acc, 0);
    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Search
                allowClear
                onSearch={setSearchText}
                style={{ width: 200 }}
                placeholder={"Cerca"}
            />
            <Table
                size={"small"}
                loading={!report.lines || loading}
                pagination={false}
                tableLayout={"fixed"}
                scroll={{ x: totalWidth, y: 500 }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                rowClassName={() => "editable-row"}
                dataSource={tableData}
                columns={finalColumns}
                summary={(data) => {
                    let totalQuantity = data.reduce((acc, x) => acc + x.quantity, 0);
                    let totalPaidQuantity = data.reduce((acc, x) => acc + x.paidQuantity, 0);

                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row style={{ fontWeight: "bold", fontSize: 16 }}>
                                <Table.Summary.Cell colSpan={4} index={0}>
                                    Totale
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} align={"end"}>
                                    <Text>{totalQuantity.toLocaleString("it-IT")}</Text>
                                </Table.Summary.Cell>
                                {report.hasPrices && (
                                    <>
                                        <Table.Summary.Cell index={5} align={"end"}>
                                            <Text type="success" style={{ paddingRight: 12 }}>
                                                {totalPaidQuantity.toLocaleString("it-IT")}
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={6} align={"end"}>
                                            <Text type={"warning"}>
                                                {(totalQuantity - totalPaidQuantity).toLocaleString(
                                                    "it-IT",
                                                )}
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={7} align={"end"}>
                                            <Text>
                                                {report.summary?.totalPrice.toLocaleString(
                                                    "it-IT",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    },
                                                )}
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={8} align={"end"}>
                                            <Text type={"success"}>
                                                {report.summary?.paidPrice.toLocaleString("it-IT", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={9} align={"end"}>
                                            <Text type={"danger"}>
                                                {report.summary?.toPayPrice.toLocaleString(
                                                    "it-IT",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    },
                                                )}
                                            </Text>
                                        </Table.Summary.Cell>
                                    </>
                                )}
                            </Table.Summary.Row>
                        </Table.Summary>
                    );
                }}
            />
        </div>
    );
}
